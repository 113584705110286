import * as api from '../../../api';
import { ACTION_GET_DETAIL_COMMENT, ACTION_GET_DETAIL_COMMENT_ERROR, ACTION_GET_DETAIL_COMMENT_SUCCESS } from '.';
import { logout } from './logout'


export const getDetailCommentRequest = () => ({
    type: ACTION_GET_DETAIL_COMMENT,
    payload: {}
})

export const getDetailCommentSuccess = data => ({
    type: ACTION_GET_DETAIL_COMMENT_SUCCESS,
    payload: { data }
})

export const getDetailCommentError = msg => ({
    type: ACTION_GET_DETAIL_COMMENT_ERROR,
    payload: { msg }
})


export const getDetailsComment = (cid) => {
    return dispatch => {
        dispatch(getDetailCommentRequest())

        api.getDetailComment(cid)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getDetailCommentError("error"));
                    return
                }
                dispatch(getDetailCommentSuccess(data.details))
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getDetailCommentError("system error"))
            })
    }


}