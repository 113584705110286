import { ACTION_GET_FAQ_CATEGORIES, ACTION_GET_FAQ_CATEGORIES_SUCCESS, ACTION_GET_FAQ_CATEGORIES_ERROR } from '.'
import { logout } from './logout'
import * as api from '../../../api'

export const getFaqCategoriesRequest = () => ({
    type: ACTION_GET_FAQ_CATEGORIES,
    payload: {}
})

export const getFaqCategoriesSuccess = data => ({
    type: ACTION_GET_FAQ_CATEGORIES_SUCCESS,
    payload: { data }
})

export const getFaqCategoriesError = msg => ({
    type: ACTION_GET_FAQ_CATEGORIES_ERROR,
    payload: { msg }
})

export const getFaqCategories = () => {
    return dispatch => {
        dispatch(getFaqCategoriesRequest())

        api.getFaqCategories()
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getFaqCategoriesError("error"));
                    return
                }
                dispatch(getFaqCategoriesSuccess(data.categories))
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getFaqCategoriesError("system error"))
            })
    }
}