import { ACTION_CLOSE, ACTION_CLOSE_SUCCESS, ACTION_CLOSE_ERROR } from ".";
import * as api from "../../../api";

import {logout} from "."

export const closeRequest = (tid) => ({
    type: ACTION_CLOSE,
    payload: {
        tid : tid
    }
})

export const closeSuccess = data => ({
    type: ACTION_CLOSE_SUCCESS,
    payload: {
        data
    }
})

export const closeError = msg => ({
    type: ACTION_CLOSE_ERROR,
    payload: {
        msg
    }
})

export const close = (tid) => {
    return dispatch => {
        dispatch(closeRequest(tid))

        api.closed(tid)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(closeError("error"));
                    return
                }
                dispatch(closeSuccess(data))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(closeError("system error"))
            });
    }
}