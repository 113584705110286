import React from 'react';
import { RiFilter2Fill } from "react-icons/ri";
import { AiOutlineDown } from "react-icons/ai";
import styles from './filter.module.scss';
import * as getCategoriesAction from '../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';


const Filter = ({ location, filterListener }) => {
    const dispatch = useDispatch();
    const allCate = useSelector(state => state.allCategories.data)

    const [filterValue, setFilterValue] = useState('All')


    const fetchCategories = async () => {
        dispatch(getCategoriesAction.getAllCategories())
    }
    useEffect(() => {
        fetchCategories()
    }, [])


    return (
        <div className={styles.filter}>
            <Button variant="outline-secondary"><RiFilter2Fill className={styles.icon} /><span>Filter</span></Button>
            <Form.Select aria-label="Default select example" onChange={(e) => filterListener(e.target.value)}>
                <option>All</option>
                {allCate != undefined && allCate.map((category, index) => (
                    <option key={category + index} >{category.name}</option>
                ))}
            </Form.Select>
        </div>
    );
}
export default Filter;