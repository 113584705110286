import React, { useState, useEffect } from 'react';
import Filter from '.\./components/Filter';
import axios from "axios";
import styles from './history.module.scss';
import { useDispatch, useSelector } from "react-redux";
import * as getThreadsAction from '../redux/store/actions';
import { TableThread } from "./table-thread";
import { Paginator } from "./paging";
import Loading from '../components/loading';
import { Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Moment from 'react-moment';
const History = ({ location }) => {

  const dispatch = useDispatch();
  const [category, setCategory] = useState('All');
  const [num_page] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [threadsFilter, setThreadFilter] = useState([])
  const [threadsPage, setThreadPage] = useState([])
  const isLoading = useSelector(state => state.historythreads.isLoading || state.allCategories.isLoading)
  const [historyThreads, setHistoryThreads] = useState([])
  const listInit = useSelector(state => state.historythreads.data)

  const fetchHistoryThreads = async () => {
    dispatch(getThreadsAction.getHistoryThreads(3))
  }


  useEffect(() => {
    console.log("check abc")
    fetchHistoryThreads()

  }, [])
  useEffect(() => {
    if(listInit != undefined){
      // setHistoryThreads([...listInit].sort((a,b) => b.id - a.id))
      setHistoryThreads([...listInit].sort((a,b) => a.closedTime > b.closedTime ? -1 : 1))
    }
  },[listInit])


  useEffect(() => {
    if (historyThreads != undefined) {
      setThreadFilter(historyThreads.filter(thread => {
        if (category == '') {
          category = "All"
        }
        if (category.toUpperCase() == "ALL") return true;
        return thread.categories.includes(category);
      }))
      if (currentPage > 1) {
        setCurrentPage(1)
      }

    }

  }, [historyThreads, category])

  useEffect(() => {
    if (threadsFilter.length % num_page == 0) {
      setTotalPage(Math.floor(threadsFilter.length / num_page))
    } else {
      setTotalPage(Math.floor(threadsFilter.length / num_page) + 1)
    }
    const startIndex = currentPage * num_page - num_page;
    const endIndex = startIndex + num_page;
    setThreadPage(threadsFilter.slice(startIndex, endIndex))

  }, [threadsFilter])

  useEffect(() => {
    const startIndex = currentPage * num_page - num_page;
    const endIndex = startIndex + num_page;
    setThreadPage(threadsFilter.slice(startIndex, endIndex))

    window.scrollTo({ behavior: 'smooth', top: '0px' });
  }, [currentPage])

  if (threadsPage !== undefined) {
    return (
      <>
      <Header />
      <div className={styles.history}>

        <div className='content'>

          <div className='row'>
            <div className="title">CLOSED THREADS</div>
            <Filter filterListener={(value) => setCategory(value)} />
            <div className={"table-content"}>
              {threadsPage != undefined && threadsPage.length > 0 ?
                <TableThread type={3} newThreads={threadsPage} /> :
                <p>No Data</p>
              }
              {
                totalPage > 1 &&
                <Paginator current_page={currentPage} total_pages={totalPage} selectPage={setCurrentPage} />
              }

            </div>
            {isLoading && <Loading />}
          </div>
        </div>
      </div>
      </>
    );
  }
}


export default History;