import { ACTION_SIGNOUT } from ".";
  
export const logoutRequest = () => ({
    type: ACTION_SIGNOUT
})

export const logout = () => {
    console.log("check")
    return dispatch => {
        dispatch(logoutRequest())
    }
}