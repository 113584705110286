import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import Filter from "../components/Filter";
import * as getThreadsAction from '../redux/store/actions';
import styles from "./home.module.scss"
import MyPagination, { Paginator } from "./paging";
import { TableThread } from "./table-thread";
import Loading from '../components/loading';
import Header from "../components/Header";
import * as action from '../redux/store/actions'

export const ThreadTab = () => {
    return (
        <>
            <Header />
            <div className={styles.thread}>

                <div>
                    <Tabs>
                        <Tab mountOnEnter eventKey={"new"} title={"NEW THREADS"} >
                            <Thread type={1} />
                        </Tab>
                        <Tab mountOnEnter eventKey={"ongoing"} title={"ONGOING THREADS"}>
                            <Thread type={2} />
                        </Tab>
                    </Tabs>
                </div>

            </div>
        </>

    )
}

const Thread = ({ type }) => {

    const [category, setCategory] = useState("All")
    const [num_page] = useState(15)
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.ongoingthreads.isLoading || state.newthreads.isLoading || state.allCategories.isLoading)
    const thread_id = useSelector(state => state.ws.tid)
    const event = useSelector(state => state.ws.event)
    const eventCounter = useSelector(state => state.ws.count)
    const CMD_USER_CREATE_THREAD = "user_create_thread"
    const detailThread = useSelector(state => state.threadDetail.data)

    const threads = useSelector(state => {
        return type == 2 ? state.ongoingthreads.data : state.newthreads.data;
    })
    const [listThreads, setListThreads] = useState([])
    const [threadsFilter, setThreadFilter] = useState([])

    const [threadsPage, setThreadPage] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)

    useEffect(() => {
        fetchThreads()
    }, [])

    useEffect(() => {
        if (event == CMD_USER_CREATE_THREAD) {
            console.log(eventCounter)
            console.log("user create")
            dispatch(action.getThreadDetail(thread_id))
        }
    }, [eventCounter])
    useEffect(() => {
        if (type == 1 && detailThread != undefined) {
            console.log("check detail change")
            console.log(detailThread)
            console.log(listThreads)
            if (listThreads.filter(e => e.id == detailThread.id).length > 0) {
                console.log("contains")
                return
            } else {
                console.log("no contain")
                setListThreads(current => {
                    const copy = [...current, detailThread]
                    return copy
                })
            }
        }
    }, [detailThread])

    useEffect(() => {
        setListThreads(threads)
    }, [threads])

    useEffect(() => {
        if (listThreads != undefined) {

            console.log("update 150524: list changes")
            console.log(listThreads)
            setThreadFilter(listThreads.filter(thread => {
                if (category.toUpperCase() == "ALL") return true;
                return thread.categories.includes(category);
            }))
            if (currentPage > 1) {
                setCurrentPage(1)
            }

        }

    }, [listThreads, category])


    let hasReloaded = localStorage.getItem('hasReloaded')
	useEffect(() => {


		if (localStorage.getItem("hasReloaded") === null) {
			console.log("set item")
			localStorage.setItem('hasReloaded', 1)
		}


	}, [])

	useEffect(() => {
		if (hasReloaded == 1) {
			console.log("check thoi ma")
			localStorage.setItem('hasReloaded', 0)
			window.location.reload()


		}
	}, [hasReloaded])


    useEffect(() => {


        if (threadsFilter.length % num_page == 0) {
            setTotalPage(Math.floor(threadsFilter.length / num_page))
        } else {
            setTotalPage(Math.floor(threadsFilter.length / num_page) + 1)
        }

        const startIndex = currentPage * num_page - num_page;
        const endIndex = startIndex + num_page;
        setThreadPage(threadsFilter.slice(startIndex, endIndex))

    }, [threadsFilter])

    useEffect(() => {

        const startIndex = currentPage * num_page - num_page;
        const endIndex = startIndex + num_page;
        setThreadPage(threadsFilter.slice(startIndex, endIndex))

        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage])


    const fetchThreads = () => {
        type == 1 ? dispatch(getThreadsAction.getNewThreads(type)) : dispatch(getThreadsAction.getOngoingThreads(type))
    }


    if (threadsPage !== undefined) {
        return (
            <>
                <Filter filterListener={(value) => setCategory(value)} />
                <div className="table-content">
                    <TableThread type={type} newThreads={threadsPage}></TableThread>
                    {
                        totalPage > 1 &&
                        // <MyPagination totPages={totalPage} currentPage={currentPage} pageClicked={setCurrentPage}  />
                        <Paginator current_page={currentPage} total_pages={totalPage} selectPage={setCurrentPage} />
                    }

                </div>
                {isLoading && <Loading />}
            </>

        )
    }

}