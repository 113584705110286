import { ACTION_VERIFY_ADMIN_REQUEST, ACTION_VERIFY_ADMIN_SUCCESS, ACTION_VERIFY_ADMIN_ERROR } from "../actions"

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const verifyAdminReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case ACTION_VERIFY_ADMIN_REQUEST:
            return { ...state, isLoading: true, erro_msg: undefined }

        case ACTION_VERIFY_ADMIN_SUCCESS:
            return { ...state, isLoading: false, data:  action.payload.data, erro_msg: undefined }

        case ACTION_VERIFY_ADMIN_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }
            
        default:
            return state;
    }
}