import React from 'react';
import { Link } from 'react-router-dom';

const NoData = () => (
  <div style={{padding: "10px"}}>
    <h3>No Data</h3>
    <Link to="/" className="link-home">
      Back to Home
    </Link>
  </div>
);

export default NoData;
