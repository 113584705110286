import {ACTION_GET_CATEGORIES, ACTION_GET_CATEGORIES_ERROR, ACTION_GET_CATEGORIES_SUCCESS} from '../actions'

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const getAllCategoriesReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case ACTION_GET_CATEGORIES:
            return { ...state, isLoading: true, erro_msg: undefined }

        case ACTION_GET_CATEGORIES_SUCCESS:
            return { ...state, isLoading: false, data:  action.payload.data, erro_msg: undefined }

        case ACTION_GET_CATEGORIES_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }
            
        default:
            return state;
    }
}