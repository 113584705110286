import { ACTION_SIGININ, ACTION_SIGNIN_SUCCESS, ACTION_SIGNIN_ERROR, ACTION_SIGNOUT } from "../actions"

const initState = {
    erro_msg: undefined,
    isLoading: false,
    isLoggedIn: false,
    adminName: undefined,
}


export const signinReducer = (state = initState, action = {}) => {


    switch (action.type) {
        case ACTION_SIGININ:
            return { ...state, isLoading: true, erro_msg: undefined }

        case ACTION_SIGNIN_SUCCESS:
            console.log("passs r")
            console.log(action.payload.data)
            return { ...state, isLoading: false,  erro_msg: undefined, isLoggedIn: true, adminName: action.payload.data.name}

        case ACTION_SIGNIN_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }
        case ACTION_SIGNOUT:
            return { ...state, isLoading: false, data: undefined, isLoggedIn: false }

        default:
            return state;
    }
}