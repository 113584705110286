import { ACTION_VERIFY_ADMIN_REQUEST, ACTION_VERIFY_ADMIN_SUCCESS, ACTION_VERIFY_ADMIN_ERROR } from ".";
import * as api from "../../../api";

export const verifyAdminRequest = (admin) => ({
    type: ACTION_VERIFY_ADMIN_REQUEST,
    payload: {
        admin: admin
    }
})

export const verifyAdminSuccess = data => ({
    type: ACTION_VERIFY_ADMIN_SUCCESS,
    payload: {
        data
    }
})


export const verifyAdminError = msg => ({
    type: ACTION_VERIFY_ADMIN_ERROR,
    payload: {
        msg
    }
})


export const verifyAdmin = (admin) => {
    return dispatch => {
        dispatch(verifyAdminRequest(admin))

        api.verify(admin)
            .then(({ data }) => {
                if (data.userid > 0) {

                    dispatch(verifyAdminSuccess(data.userid));
                    return
                }
                dispatch(verifyAdminError("error"));

            }).catch((err) => {
                console.log(err)
                dispatch(verifyAdminError("system error"))
            });
    }
}