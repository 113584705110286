export const LOGIN_ENDPOINT = "/signin";
export const VERIFY_TOKEN_ENDPOINT = "/tokensignin";
export const GET_THREADS_ENDPOINT = "/cs/sp/threads";
export const GET_ALL_COMMENT_ENDPOINT = "/cs/sp/comments";
export const COMMENT_REPLY_ENDPOINT = "/cs/sp/threads/comment";
export const CLOSED_ENDPOINT = '/cs/sp/threads/close';
export const GET_FAQS_ENDPOINT = '/cs/sp/faqs';
export const CREATE_FAQ_ENDPOINT = '/cs/sp/faqs/create';
export const DETAIL_FAQS_ENDPOINT = '/cs/sp/faqs/details';
export const DELETE_FAQS_ENDPOINT = '/cs/sp/faqs/delete';
export const UPDATE_FAQS_ENDPOINT = '/cs/sp/faqs/update';
export const GET_ALL_CATEGORY_ENDPOINT = "/cs/sp/categories";
export const GET_FAQ_CATEGORY_ENDPOINT = "/cs/sp/faqcategories";
export const THREAD_DETAIL_ENDPOINT = "/cs/sp/threads/details";
export const GET_DETAIL_COMMENT_ENDPOINT = "/cs/sp/comments/details";
export const GET_NOTIFY_ENDPOINT = "/cs/sp/notifies";
export const VERIFY_ADMIN_ENDPOINT = "/tokenverify";
export const INVITE_ENDPOINT = "/cs/sp/threads/invite";