import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

export const Paginator = ({ current_page, total_pages, selectPage }) => {
	const num_page_show = 3;
	const [hasPrev, setHasPrev] = useState(false)
	const [hasNext, setHasNext] = useState(false)
	const [hasElipsisPrev, setHasElipsisPrev] = useState(false)
	const [hasElipsisNext, setHasElipsisNext] = useState(false)
	const [pageShow, setPageShow] = useState([])

	useEffect(() => {


	}, [])

	useEffect(() => {
		setHasPrev(current_page !== 1)
		setHasNext(current_page !== total_pages)
		setHasElipsisPrev(current_page !== 1 && current_page !== 2)
		setHasElipsisNext(current_page !== total_pages && current_page !== total_pages - 1)

		let arrShow = []
		// const last = current_page + num_page_show - 3 > total_pages ? total_pages : current_page + num_page_show - 3;
		const last = current_page + 1 > total_pages ? total_pages : current_page + 1;
		if (total_pages < 3) {
			for (let i = 1; i <= total_pages; i++) {
				arrShow.push(i)
			}
		} else {
			// let n;
			// if (current_page % num_page_show == 0) {
			// 	n = Math.floor(current_page / num_page_show)
			// } else {
			// 	n = Math.floor(current_page / num_page_show) + 1
			// }
			// let maxShow = n * num_page_show
			// if (maxShow <= total_pages) {
			// 	for (let i = maxShow - 2; i <= maxShow; i++) {
			// 		arrShow.push(i)
			// 	}
			// } else {
			// 	for (let i = maxShow - 2; i <= total_pages; i++) {
			// 		arrShow.push(i)
			// 	}
			// }
			if (current_page <= 2) {
				for (let i = 1; i <= 3; i++) {
					arrShow.push(i)
				}
			}
			else if (current_page + 1 <= total_pages) {
				for (let i = current_page - 1; i <= current_page + 1; i++) {
					arrShow.push(i)
				}
			}
			else {
				for (let i = current_page - 2; i <= total_pages; i++) {
					arrShow.push(i)
				}
			}


		}

		setPageShow(arrShow)
	}, [total_pages, current_page])

	// useEffect(() => {
	// 	console.log(pageShow)
	// }, [pageShow])
	return (
		<div style={{ justifyContent: "center" }}>
			{total_pages > 0 && <Pagination
			>
				{hasPrev && <Pagination.First
					key={"firstpage"}
					onClick={
						current_page === 1
							? () => { }
							: () => {
								selectPage(1);
							}
					}
				/>}
				{/* {hasPrev && <Pagination.Prev
					key={"prevpage"}
					onClick={
						() => { selectPage(current_page - 1) }
					}
				/>} */}
				{hasElipsisPrev && <Pagination.Ellipsis />}
				{
					pageShow.map((value, index) => (
						<Pagination.Item onClick={() => selectPage(value)} active={current_page === value} key={value + index}>{value}</Pagination.Item>
					))
				}


				{hasElipsisNext && <Pagination.Ellipsis />}
				{/* {hasNext && <Pagination.Next
					key={"nextpage"}
					onClick={
						() => { selectPage(current_page + 1) }
					}
				/>} */}
				{hasNext && <Pagination.Last
					key={"lastpage"}
					onClick={
						current_page === total_pages
							? () => { }
							: () => {
								selectPage(total_pages);
							}
					}
				/>}
			</Pagination>}
		</div>

	)
}
export default function MyPagination(props) {
	const [pageArray, setPageArray] = React.useState([]);

	React.useEffect(() => {
		var totPages = parseInt(props.totPages);
		var currentPage = parseInt(props.currentPage);
		var pageArr = [];
		if (totPages > 1) {
			if (totPages <= 9) {
				var i = 1;
				while (i <= totPages) {
					pageArr.push(i);
					i++;
				}
			} else {
				if (currentPage <= 5) pageArr = [1, 2, 3, 4, 5, 6, 7, 8, "", totPages];
				else if (totPages - currentPage <= 4)
					pageArr = [
						1,
						"",
						totPages - 7,
						totPages - 6,
						totPages - 5,
						totPages - 4,
						totPages - 3,
						totPages - 2,
						totPages - 1,
						totPages
					];
				else
					pageArr = [
						1,
						"",
						currentPage - 3,
						currentPage - 2,
						currentPage - 1,
						currentPage,
						currentPage + 1,
						currentPage + 2,
						currentPage + 3,
						"",
						totPages
					];
			}
		}
		setPageArray(pageArr);
	}, []);

	return (
		<React.Fragment>
			{props.children}
			<div style={{ marginTop: "15px" }}>
				<Pagination style={{ justifyContent: "center" }}>
					{pageArray.map((ele, ind) => {
						const toReturn = [];

						if (ind === 0) {
							toReturn.push(
								<Pagination.First
									key={"firstpage"}
									onClick={
										props.currentPage === 1
											? () => { }
											: () => {
												props.pageClicked(1);
											}
									}
								/>
							);

							toReturn.push(
								<Pagination.Prev
									key={"prevpage"}
									onClick={
										props.currentPage === 1
											? () => { }
											: () => {
												props.pageClicked(props.currentPage - 1);
											}
									}
								/>
							);
						}

						if (ele === "") toReturn.push(<Pagination.Ellipsis key={ind} />);
						else
							toReturn.push(
								<Pagination.Item
									key={ind}
									active={props.currentPage === ele ? true : false}
									onClick={
										props.currentPage === ele
											? () => { }
											: () => {
												props.pageClicked(ele);
											}
									}
								>
									{ele}
								</Pagination.Item>
							);

						if (ind === pageArray.length - 1) {
							toReturn.push(
								<Pagination.Next
									key={"nextpage"}
									onClick={
										props.currentPage === ele
											? () => { }
											: () => {
												props.pageClicked(props.currentPage + 1);
											}
									}
								/>
							);

							toReturn.push(
								<Pagination.Last
									key={"lastpage"}
									onClick={
										props.currentPage === ele
											? () => { }
											: () => {
												props.pageClicked(ele);
											}
									}
								/>
							);
						}

						return toReturn;
					})}
				</Pagination>
			</div>
		</React.Fragment>
	);
}