import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Switch,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import Home from "./routes/home";
import Header from "./components/Header";
import History from "./routes/history";
import FAQ from "./routes/faq";
import Login from "./routes/login";
import "./index.css";
import { render } from 'react-dom';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import WebSocketProvider from './websocket'

import 'bootstrap/dist/css/bootstrap.min.css';

// const root = ReactDOM.createRoot(
//   document.getElementById("root")
// );
// root.render(
//   <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<App />} />
//       <Route path="home" element={<Home />} />
//       <Route path="history" element={<History />} />
//       <Route path="header" element={<Header />} />
//       <Route path="faq" element={<FAQ />} />
//       <Route path="login" element={<Login />} />
//     </Routes>
//   </BrowserRouter>
// );
//SPA
const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <WebSocketProvider>
        <App />
      </WebSocketProvider>
    </Provider>
  </BrowserRouter>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();