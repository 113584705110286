import { Button, Form } from 'react-bootstrap';
import { BsChevronLeft } from 'react-icons/bs';
import { ImAttachment } from 'react-icons/im';
import { Link, Redirect, useParams } from 'react-router-dom';
import styles from './threadDetails.module.scss';
import ImageViewer from 'react-simple-image-viewer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/store/actions';
import Header from '../components/Header';
import { domain } from './domain';
import moment from 'moment';
import { FilesReview } from '../components/filesReview';
import { getBase64, randomString } from '../base';
import ReactStars from 'react-rating-stars-component';
import Loading from '../components/loading';
import socketIOClient from 'socket.io-client'
import { userConfirm } from '../redux/store/actions';
import imageCompression from 'browser-image-compression';
import { AiOutlineSearch } from 'react-icons/ai';

export const ThreadDetails = () => {

    // const CMD_ADMIN_COMMENT = "admin_comment"
    // const CMD_ADMIN_REQUEST_CLOSE = "admin_request_close"
    const CMD_USER_COMMENT = "user_comment"
    const CMD_USER_CONFIRM_CLOSE = "user_confirm_close"
    const CMD_USER_RATE = "user_rate"

    const [msgText, setMsgText] = useState("")
    const msgTextRef = useRef()
    const fileRef = useRef()
    const contentRef = useRef()

    const { tid } = useParams();

    const dispatch = useDispatch()

    const thread_detail = useSelector(state => state.threadDetail.data)
    const allComments = useSelector(state => state.comments.data)
    const addComment = useSelector(state => state.reply.data)
    const isLoading = useSelector(state => state.threadDetail.isLoading || state.comments.isLoading || state.close.isLoading)
    const err_detail = useSelector(state => state.threadDetail.erro_msg)
    const loadingDetail = useSelector(state => state.threadDetail.isLoading)
    const [localComments, setLocalComments] = useState({})
    const [currentImage, setCurrentImage] = useState(0);
    const [imagesView, setImagesView] = useState([])
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [showCommentForm, setShowCommentForm] = useState(false)
    const [selectedFile, setSelectedFile] = useState({});
    const [showReviewFiles, setShowReviewFiles] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showRating, setShowRating] = useState(false)
    const [isRated, setIsRated] = useState(false)
    const close_err = useSelector(state => state.close.erro_msg)
    const close_loading = useSelector(state => state.close.isLoading)
    const [isNoData, setIsNoData] = useState(false)
    const [err, setErr] = useState(false)
    const whileLists = useSelector(state => state.notifies.data)

    const [starNumber, setStarNumber] = useState(0)
    const [messages, setMessages] = useState([])
    const cid = useSelector(state => state.ws.cid)
    const userConfirm = useSelector(state => state.ws.status)
    const userRateStarNumber = useSelector(state => state.ws.star)
    const userRateThreadId = useSelector(state => state.ws.tid)
    const [isThread, setIsThread] = useState(false)
    const [isUserRating, setIsUserRating] = useState(false)
    const commentDetail = useSelector(state => state.detailComment.data)
    const event = useSelector(state => state.ws.event)
    const eventCounter = useSelector(state => state.ws.count)
    const detailCount = useSelector(state => state.threadDetail.count)
    const [showRequestToJoin, setShowRequestToJoin] = useState(false)
    const [showRequestConfirm, setShowRequestConfirm] = useState(false)
    const adminID = useSelector(state => state.verifyAdmin.data)
    const [inviteId, setInviteId] = useState(0);
    const inviteRef = useRef()
    const err_invite = useSelector(state => state.invite.erro_msg)
    const count_invite = useSelector(state => state.invite.count)
    const [isAccept, setIsAccept] = useState(true)
    const newthreads = useSelector(state => state.newthreads.data)
    const ongoingthreads = useSelector(state => state.ongoingthreads.data)
    const historythreads = useSelector(state => state.historythreads.data)
    const [uid, setUid] = useState(0)


    useEffect(() => {
        console.log("event change")
        // if (event == CMD_USER_CONFIRM_CLOSE && userConfirm == false && userRateThreadId == tid) {
        //     setShowCommentForm(true)
        //     setShowRating(false)
        // }
        if (event == CMD_USER_RATE && userRateThreadId == tid) {

            setStarNumber(userRateStarNumber)
            setShowRating(false)
        }
    }, [eventCounter])

    useEffect(() => {
        if(newthreads !== undefined){
            console.log(newthreads.length);
            newthreads.map((thread) => {
                if(thread.id == tid){
                    console.log(thread.id);
                    setUid(thread.userid);
                }
            })
        }
        if(ongoingthreads !== undefined){
            console.log(ongoingthreads.length);
            ongoingthreads.map((thread) => {
                if(thread.id == tid){
                    console.log(thread.id)
                    setUid(thread.userid);
                }
            })
        }
        if(historythreads !== undefined){
            console.log(historythreads.length);
            historythreads.map((thread) => {
                if(thread.id == tid){
                    console.log(thread.id);
                    setUid(thread.userid);
                }
            })
        }
    }, [newthreads , ongoingthreads, historythreads])

    useEffect(() => {
        if (cid > 0 && event == CMD_USER_COMMENT) {
            console.log("change cid")
            console.log(cid)
            dispatch(actions.getDetailsComment(cid))
        }
    }, [cid])
    useEffect(() => {
        console.log("get detail comment success")
        console.log(commentDetail)
        console.log(allComments)
        console.log(localComments)

        if (commentDetail !== undefined && commentDetail.tid == tid) {
            let date = moment().format("MMMM Do YYYY")
            if (localComments[date] == undefined) {
                localComments[date] = []
            }
            localComments[date] = [...localComments[date], { ...commentDetail, time: moment().format("HH:mm"), new: false }]
            setLocalComments(current => {
                const copy = { ...current }
                return copy
            })
        }
    }, [commentDetail])

    useEffect(() => {
        setShowRating(true)
    }, [starNumber])

    useEffect(() => {
        if (imagesView.length > 0)
            setIsViewerOpen(true);
    }, [imagesView])



    useEffect(() => {

        if (thread_detail != undefined && adminID != undefined) {
            console.log(thread_detail)
            console.log(adminID)
            console.log("check")
            console.log(thread_detail.handlers.length)
            dispatch(actions.getAllComments(tid))

            setIsRated(thread_detail.star > 0)
            if (thread_detail.closed) {
                setShowRating(true)
                setShowCommentForm(false)
            } else {
                setShowCommentForm(true)
                setShowRating(false)
            }
            if (thread_detail.closed) {
                setIsAccept(true)
            } else {
                if (thread_detail.handlers.some(id => id == adminID) || thread_detail.handlers.length == 0) {


                } else {
                    setIsAccept(false)
                }
            }






        }
    }, [thread_detail, adminID])

    useEffect(() => {
        if (close_err == undefined) {
            setShowRating(true)
            setShowCommentForm(false)
        } else {
            alert('An error has occurred, please try again')
        }
    }, [close_loading])

    useEffect(() => {
    }, [showCommentForm, showRating, isRated])

    useEffect(() => {
        console.log("allComments change")
        if (allComments == undefined) return;
        console.log(allComments)
        let group = {}
        allComments.map((comment) => {
            let m = moment(new Date(comment.time))
            let date = m.format("MMMM Do YYYY")
            if (group[date] == undefined) {
                group[date] = []
            }
            group[date] = [...group[date], { ...comment, time: m.format("HH:mm") }]

        })
        setLocalComments(group)

    }, [allComments])

    useEffect(() => {
        if (addComment != undefined) {
            let date = moment(new Date(addComment.time)).format("MMMM Do YYYY")
            if (localComments[date] == undefined) {
                localComments[date] = []
            }

            let index = localComments[date].findIndex(obj => obj.request === addComment.request)
            let temp_state = [...localComments[date]];
            let temp_element = { ...temp_state[index] };
            temp_element.isSent = true
            temp_state[index] = temp_element

            setLocalComments(current => {
                const copy = { ...current }
                copy[date] = temp_state;
                return copy
            })
        }
    }, [addComment])

    useEffect(() => {
        contentRef?.current?.scrollTo({ top: contentRef.current.scrollHeight, behavior: 'smooth' });
    }, [localComments])

    useEffect(() => {
        console.log("pass det")

        if (err_detail !== undefined) {

            setIsNoData(true)

        }
    }, [detailCount])



    // useEffect(() => {
    //     console.log(err)
    //     if (!loadingDetail && err) {
    //         console.log("loi roi")
    //         setIsNoData(true)

    //     }
    // }, [err])

    useEffect(() => {
        console.log("detail pgae")
        console.log(tid)

        setIsRated(false)
        setShowRating(false)
        setShowCommentForm(false)
        setIsUserRating(false)
        setIsNoData(false)
        setShowRequestToJoin(false)
        setIsAccept(true)
        dispatch(actions.getThreadDetail(tid))
        dispatch(actions.verifyAdmin(true))
        dispatch(actions.getNewThreads(1))
        dispatch(actions.getOngoingThreads(2))
        dispatch(actions.getHistoryThreads(3))

    }, [tid])

    const openImageViewer = useCallback((index, images) => {
        setCurrentImage(index);

        setImagesView(images.map((img) => img.length > 100 ? img : domain + img))
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const handleFileChange = event => {
        let objs = {}
        Object.keys(event.target.files).map((key) => {

            getBase64(event.target.files[key]).then(f => {
                const options = {
                    maxSizeMB: 0.5,
                    maxWidthOrHeight: 1280,
                    useWebWorker: true
                }

                imageCompression(event.target.files[key], options).then(file => {
                    imageCompression.getDataUrlFromFile(file).then(fNew => {
                        objs[key] = fNew
                        // arrImgName.push(event.target.files[key].name)
                        setSelectedFile({ ...selectedFile, ...objs })
                    })


                })
                // objs[key] = f
                // setSelectedFile({ ...selectedFile, ...objs })
            })

            // getBase64(event.target.files[key]).then(f => {
            //     objs[key] = f
            //     setSelectedFile({ ...selectedFile, ...objs })
            // })
        })

        setShowReviewFiles(true)
        //to do

        // 👇️ reset file input
        // event.target.value = null;
    };

    function closeThread(e) {
        e.preventDefault();
        dispatch(actions.close(tid))
        setShowRating(true)
        setShowCommentForm(false)

    }

    const closeReviewFiles = () => {
        setShowReviewFiles(false)
        setSelectedFile({})
    }

    const handleRequestToJoin = (e) => {
        e.preventDefault()
        setShowRequestToJoin(false)

    }

    const handleConfirmation = (e, b) => {
        console.log("confirm")
        setShowRequestConfirm(false)
        if (!b) {
            setShowCommentForm(true)
            setShowRating(false)
        } else {
            setShowCommentForm(false)
            setShowRating(false)
        }
    }

    // useEffect(() => {
    //     if (err_invite != undefined) {
    //         alert("Invite Success")
    //     } 
    //     // else {
    //     //     alert("Invite Error")
    //     // }
    // }, [count_invite])
    const handleInvite = (e) => {
        e.preventDefault()
        if (inviteId != 0) {
            inviteRef.current.value = ('')
            const invite = {
                tid: tid,
                mid: inviteId
            }
            dispatch(actions.inviteAdminJoin(invite))
        }
    }

    const sendComment = (e) => {
        e.preventDefault()
        let arrImg = []
        if (Object.keys(selectedFile).length > 5) {
            alert("You are only allowed to upload a maximum of 5 files")
            return;
        }

        Object.keys(selectedFile).map(index => {
            arrImg.push(selectedFile[index])
        })
        if (msgText.trim() !== '' || arrImg.length > 0) {
            const newComment = {
                tid: tid,
                text: msgText,
                images: arrImg,
                request: randomString(16)
            }

            msgTextRef.current.focus()
            setMsgText("")
            if (showReviewFiles) {
                closeReviewFiles(false)
            }

            let date = moment().format("MMMM Do YYYY")
            if (localComments[date] == undefined) {
                localComments[date] = []
            }
            localComments[date] = [...localComments[date], { ...newComment, time: moment().format("HH:mm"), new: true, mid: adminID }]

            dispatch(actions.reply(newComment));
        }
        if (msgText.trim() == '') {
            console.log("pass roi")
            setMsgText("")
        }
    }

    const onRemoveReviewImage = (index) => {
        setSelectedFile(current => {
            const copy = { ...current }
            delete copy[index];
            return copy
        })
    }


    return (
        <>
            {!isAccept && <Redirect to={"/"} exact />}
            {isNoData && <Redirect to={"/no_data"} exact />}
            <Header />
            <div className={styles.thread_details}>
                <FilesReview
                    onRemoveImage={onRemoveReviewImage}
                    sendComment={sendComment}
                    show={showReviewFiles}
                    files={selectedFile}
                    text={msgText}
                    onClose={() => closeReviewFiles()}
                    onTextChange={(e) => setMsgText(e.target.value)} />
                {isViewerOpen && (
                    <ImageViewer
                        src={imagesView}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                )}
                {thread_detail &&
                    <>
                        <div className="breakcum">
                            <Link to={thread_detail.closed ? '/history' : '/'}><BsChevronLeft className="backIcon" /></Link>
                            <div className={"category-tag"}>{thread_detail.categories.length > 0 ? thread_detail.categories[0] : ""}</div>
                            <div className='threadID'>#Thread: {thread_detail.id} &nbsp;&nbsp; UserID: {uid}</div>
                            {/* <Form className='invite'>
                                <button className='btnInvite' onClick={(e) => { handleInvite(e) }}>INVITE</button>
                                <input className='inputFormInvite' ref={inviteRef} placeholder='Admin ID' onChange={(e) => setInviteId(e.target.value)}></input>
                            </Form> */}
                            <Form className='searchFrom'>
                                {/* <button className='btn' onClick={(e) => { handleInvite(e) }}><AiOutlineSearch style={{ color: "white", fontSize: "1.5em" }} /></button> */}
                                <Button className='btn' onClick={(e) => { handleInvite(e) }}>INVITE</Button>
                                <input className='inputText' ref={inviteRef} placeholder='Admin ID' onChange={(e) => setInviteId(e.target.value)} ></input>
                            </Form>
                        </div>
                        <div className='content' ref={contentRef}>
                            <div className='question'>
                                <div className={'title'}>{thread_detail.text}</div>
                                <div className='thumb'>
                                    {thread_detail.images.map((src, index) => (
                                        <img
                                            src={domain + src}
                                            onClick={() => openImageViewer(index, thread_detail.images)}
                                            width="300"
                                            key={index}
                                            style={{ margin: '2px' }}
                                            alt=""
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className='comments'>
                                {Object.keys(localComments).map((key, index) => {
                                    if (key != 'Invalid date') {
                                        return (
                                            <div key={key + index}><div className='date'>{key}</div>
                                                {
                                                    localComments[key].map((comment, ind) => {
                                                        return (
                                                            <div className='item' key={ind}>
                                                                <div className='name'>{comment.mid > 0 ? `Admin-${comment.mid}` : `User-${comment.uid}`}</div>
                                                                <div className='msg'>
                                                                    <div className='left'>
                                                                        {comment.text.trim().length > 0 && <div className={comment.mid > 0 ? "text admin" : "text user"}>{comment.text}</div>}
                                                                        <div className='thumb'>
                                                                            {comment.images.map((src, index) => (
                                                                                <img
                                                                                    src={comment.new ? src : domain + src}
                                                                                    onClick={() => openImageViewer(index, comment.images)}
                                                                                    width="300"
                                                                                    key={index}
                                                                                    style={{ margin: '2px' }}
                                                                                    alt=""
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </div>

                                                                    <div className='time'>{comment.time}</div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }



                                })}

                            </div>
                        </div>

                        <div className='action'>
                            {showCommentForm && <div className='comment-form'>
                                {/* <Form.Control className="inputtext" type="text" maxLength={255} placeholder="Add a comment..." ></Form.Control> */}
                                <Button onClick={(e) => { closeThread(e) }} className="btnclose">Close Thread</Button>
                                <div className="input-group">
                                    <input placeholder='Add a comment...' onKeyUp={(event) => { if (event.key === "Enter") sendComment(event) }} ref={msgTextRef} value={msgText} onChange={(e) => setMsgText(e.target.value)} maxLength={255} type="text" className="form-control input-text" aria-label="Amount (to the nearest dollar)" />
                                    <div className="input-group-append">
                                        <span className="input-group-text">{msgText.length} / 255</span>
                                    </div>
                                </div>
                                <ImAttachment className="btnattach" onClick={() => fileRef.current.click()} />
                                <input multiple type="file" accept="image/x-png,image/gif,image/jpeg" style={{ display: "none" }} ref={fileRef} onChange={handleFileChange} />
                                <Button onClick={sendComment} className="btncomment" >Add Comment</Button>
                            </div>}

                            {showRating && <div className="ratingForm">
                                {/* {!isRated && <div className="rateText">How is the quality of our support? Please help us to rate below</div>} */}
                                <ReactStars disable={false} classNames="stars"
                                    count={5}
                                    size={50}
                                    activeColor="#ffd700"
                                    value={starNumber > 0 ? starNumber : thread_detail.star}
                                    edit={false}

                                />
                            </div>}

                            {/* {showRequestToJoin && <div className='requestJoin'>
                                <Button onClick={handleRequestToJoin} className="btnrequest">Request to Join</Button>
                            </div>}

                            {showRequestConfirm && <div className="confirm">
                                <div className="text">
                                    B wants to join this thread. Do you accept the request?
                                </div>
                                <div className="buttons">
                                    <Button className='deny' onClick={(e) => handleConfirmation(e, false)}>Reject</Button>
                                    <Button className='accept' onClick={(e) => handleConfirmation(e, true)}>Accept</Button>
                                </div>
                            </div>} */}

                            {isLoading &&
                                <Loading />
                            }

                        </div>
                    </>
                }

            </div></>
    )
    // }

}
