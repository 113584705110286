import { ACTION_GET_FAQS, ACTION_GET_FAQS_SUCCESS, ACTION_GET_FAQS_ERROR, ACTION_UPDATE_LIST_FAQ_SUCCESS, ACTION_UPDATE_AFTER_DELETE_FAQ_SUCCESS, ACTION_EDIT_FAQ_SUCCESS } from '../actions';
const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const getAllFaqsReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case ACTION_GET_FAQS:
            return { ...state, isLoading: true, erro_msg: undefined }

        case ACTION_GET_FAQS_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data, erro_msg: undefined }

        case ACTION_UPDATE_LIST_FAQ_SUCCESS:

            let nextState = { ...state, isLoading: false }
            nextState.data = [...nextState.data, action.payload.data]
            return nextState

        case ACTION_UPDATE_AFTER_DELETE_FAQ_SUCCESS:
            let nextStateDel = { ...state, isLoading: false }
            nextStateDel.data = nextStateDel.data.filter((faqList) => faqList.id !== action.payload.data)
            return nextStateDel

        case ACTION_EDIT_FAQ_SUCCESS:
            let editState = {...state, isLoading: false}
            editState.data = editState.data.map(item => {
                if(item.id == action.payload.data.fid){
                    return {...item, id: action.payload.data.fid, title: action.payload.data.title, text: action.payload.data.text, categories: action.payload.data.categories}
                }
                return item
            })
            return editState
            

        case ACTION_GET_FAQS_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }

        default:
            return state;
    }
}