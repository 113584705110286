import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import styles from './table-thread.module.css';
import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../redux/store/actions';

export const TableThread = ({ type, newThreads }) => {


    const [listThreadId, setListThreadId] = useState([])
    const dispatch = useDispatch()

    const CMD_USER_COMMENT = "user_comment"
    const CMD_USER_CONFIRM_CLOSE = "user_confirm_close"
    const CMD_USER_RATE = "user_rate"

    const event = useSelector(state => state.ws.event)
    const thread_id = useSelector(state => state.ws.tid)
    const eventCounter = useSelector(state => state.ws.count)
    const whileLists = useSelector(state => state.notifies.data)

    useEffect(() => {
        // console.log("event change")
        // console.log(eventCounter)
        if (event == CMD_USER_COMMENT || event == CMD_USER_CONFIRM_CLOSE || event == CMD_USER_RATE) {
            // console.log(thread_id)
            // console.log(event)
            // console.log(listThreadId)
         
            if (whileLists.filter(item => item.threadId == thread_id).length > 0) {
                // console.log("co roi")
                return
            } else {
                // console.log("chua co")
                dispatch(actions.addThreadIntoNotifyList(thread_id))
            }
        }
    }, [eventCounter])

    useEffect(() => {
        console.log("check while list id")
        if(whileLists !== undefined){
            // console.log(whileLists)
            setListThreadId(whileLists.map((item) => item.threadId))
        }
    }, [whileLists])
    useEffect(() => {
        // console.log("start point")
        dispatch(actions.getNotifies())
        // console.log(newThreads)
       
    },[])

    useEffect(() =>{
        console.log("list thay doi")
        console.log(listThreadId)
    }, [listThreadId])


    return (
        <Table responsive className={styles.tableThread}>
            <thead>
                <tr >
                    <th>Thread ID</th>
                    <th>Category</th>
                    <th>Admin</th>
                    <th className='th-lg'>Description</th>
                    <th>User name</th>
                    <th>User ID</th>
                    <th>Time</th>
                </tr>
            </thead>
            <tbody>
                {newThreads.map((thread, index) => {
                    let cat = thread.categories.length > 0 ? thread.categories[0] : "";
                    return (
                        <tr key={thread.id + index}>
                            <td >#{thread.id}</td>
                            <td ><span className="category-tag" >{thread.categories[0]}</span></td>
                            <td>{thread.handlers.join()}</td>
                            <td >
                                <Link to={{ pathname: "/thread_detail/" + thread.id, state: { id: thread.id, cate: cat, detail: thread.text, listImg: thread.images, starNumber: thread.star, isClose: thread.closed } }}>
                                    {/* <div> */}
                                    <div className={styles.detailThread}>
                                        <div  className={styles.text}>{thread.text}
                                            {type == 2 && listThreadId != undefined && listThreadId.some(id => id == thread.id) && <div className={styles.noti}></div>}
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </Link>
                            </td>
                            <td >___</td>
                            <td >{thread.userid}</td>
                            <td ><Moment format="YYYY/MM/DD HH:mm:ss">{thread.closed ? thread.closedTime : thread.time}</Moment></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
