import { Form, Modal } from "react-bootstrap"
import { AiOutlineSend } from "react-icons/ai"
import { BsFillTrashFill } from "react-icons/bs"
import styles from "./filesReview.module.scss"

export const FilesReview = ({text, files, show, onClose, onTextChange, sendComment, onRemoveImage}) => {
    
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="sm" className={styles.review_image} show={show} onHide={onClose}>
            <Modal.Body>
                <div className="listFileUpload">
                    {Object.keys(files).map((index) => {
                        return (
                            <div key={files[index] + index} className="item">
                                <img src={files[index]} alt="" />
                                <BsFillTrashFill className="delete" onClick={() => onRemoveImage(index)} />
                            </div>
                        )
                    })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Form.Control onKeyUp={(event) => {if(event.key === "Enter") sendComment(event)}} value={text} className="inputtext" type="textarea" maxLength={255} onChange={onTextChange}  ></Form.Control>
                {/* <span className="count_riview">{text.length} / 255</span> */}
                <AiOutlineSend className="btnsend" onClick={sendComment} />
            </Modal.Footer>
        </Modal>
    )
}