import {logout} from "."
import * as api from '../../../api';
import { ACTION_CREATE_FAQ, ACTION_CREATE_FAQ_SUCCESS, ACTION_CREATE_FAQ_ERROR, ACTION_UPDATE_LIST_FAQ_SUCCESS } from '.';

export const createFaqRequest = () => ({
    type: ACTION_CREATE_FAQ,
    payload: {}
})

export const createFaqSuccess = data => ({
    type: ACTION_CREATE_FAQ_SUCCESS,
    payload: { data }
})

export const createNewFaqSuccess = data => ({
    type: ACTION_UPDATE_LIST_FAQ_SUCCESS,
    payload: { data }
})

export const createFaqError = msg => ({
    type: ACTION_CREATE_FAQ_ERROR,
    payload: { msg }
})

export const createFaq = (newFaq) => {
    return dispatch => {
        dispatch(createFaqRequest())

        api.createFaq(newFaq)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(createFaqError("error"));
                    return
                }
                dispatch(createNewFaqSuccess({...newFaq, id: data.fid}))
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(createFaqError("system error"))
            })
    }
}

