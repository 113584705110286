import { logout } from "./logout";
import { ACTION_GET_COMMENT, ACTION_GET_COMMENT_SUCCESS, ACTION_GET_COMMENT_ERROR } from "."
import * as api from "../../../api";

export const getAllCommentsRequest = () => ({
    type: ACTION_GET_COMMENT,
    payload: {}
})

export const getAllCommentsSuccess = data => ({
    type: ACTION_GET_COMMENT_SUCCESS,
    payload: { data }
})

export const getAllCommentsError = msg => ({
    type: ACTION_GET_COMMENT_ERROR,
    payload: { msg }
})

export const getAllComments = (tid) => {
    return dispatch => {
        dispatch(getAllCommentsRequest())

        api.getAllComments(tid)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getAllCommentsError("error"));
                    return
                }
                dispatch(getAllCommentsSuccess(data.list))
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getAllCommentsError("system error"))
            })
    }
}