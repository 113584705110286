export * from "./token-login";
export * from "./get-threads";
export * from "./signin";
export * from "./logout";
export * from "./get-comment";
export * from './reply';
export * from './close';
export * from './getFaqs';
export * from './createFaq';
export * from './detailFaq';
export * from './deleteFaq';
export * from './updateFaq';
export * from './getCategories';
export * from './getFaqsCate';
export * from './thread_detail';
export * from './getDetailComment';
export * from './ws';
export * from './listThread'
export * from './getnotify'
export * from './verify';
export * from './invite';

export const ACTION_SIGININ =  "SIGNIN";
export const ACTION_SIGNIN_SUCCESS =  "SIGNIN_SUCCESS";
export const ACTION_SIGNIN_ERROR =  "SIGNIN_ERROR";
export const ACTION_SIGNOUT = "ACTION_SIGNOUT";
export const ACTION_SIGNOUT_SUCESS = "ACTION_SIGNOUT_SUCESS";
export const ACTION_SIGNOUT_ERROR = "ACTION_SIGNOUT_ERROR";


export const ACTION_AUTHEN_VERIFY_TOKEN =  "AUTHEN_VERIFY_TOKEN";
export const ACTION_AUTHEN_VERIFY_TOKEN_SUCCESS =  "AUTHEN_VERIFY_TOKEN_SUCCESS";
export const ACTION_AUTHEN_VERIFY_TOKEN_ERROR =  "AUTHEN_VERIFY_TOKEN_ERROR";

export const ACTION_GET_NEW_THREADS = "GET_NEW_THREADS";
export const ACTION_GET_NEW_THREADS_SUCCESS = "GET_NEW_THREADS_SUCCESS";
export const ACTION_GET_NEW_THREADS_ERROR = "GET_NEW_THREADS_ERROR";

export const ACTION_GET_THREAD_DETAIL = "GET_THREAD_DETAIL";
export const ACTION_GET_THREAD_DETAIL_SUCCESS = "GET_THREAD_DETAIL_SUCCESS";
export const ACTION_GET_THREAD_DETAIL_ERROR = "GET_THREAD_DETAIL_ERROR";

export const ACTION_GET_ONGOING_THREADS = "GET_ONGOING_THREADS";
export const ACTION_GET_ONGOING_THREADS_SUCCESS = "GET_ONGOING_THREADS_SUCCESS";
export const ACTION_GET_ONGOING_THREADS_ERROR = "GET_ONGOING_THREADS_ERROR";

export const ACTION_GET_HISTORY_THREADS = "GET_HISTORY_THREADS";
export const ACTION_GET_HISTORY_THREADS_SUCCESS = "GET_HISTORY_THREADS_SUCCESS";
export const ACTION_GET_HISTORY_THREADS_ERROR = "GET_HISTORY_THREADS_ERROR";

export const ACTION_REPLY = "REPLY";
export const ACTION_REPLY_SUCCESS = "REPLY_SUCCESS";
export const ACTION_REPLY_ERROR = "REPLY_ERROR";

export const ACTION_CLOSE = "CLOSE";
export const ACTION_CLOSE_SUCCESS = "CLOSE_SUCCESS";
export const ACTION_CLOSE_ERROR = "CLOSE_ERROR";

export const ACTION_GET_FAQS = "GET_FAQS";
export const ACTION_GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const ACTION_GET_FAQS_ERROR = "GET_FAQS_ERROR";


export const ACTION_CREATE_FAQ = "CREATE_FAQ";
export const ACTION_CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";
export const ACTION_CREATE_FAQ_ERROR = "CREATE_FAQ_ERROR";
export const ACTION_UPDATE_LIST_FAQ_SUCCESS = "UPDATE_LIST_FAQ_SUCCESS";

export const ACTION_DETAIL_FAQ = "DETAIL_FAQ";
export const ACTION_DETAIL_FAQ_SUCCESS = "DETAIL_FAQ_SUCCESS";
export const ACTION_DETAIL_FAQ_ERROR = "DETAIL_FAQ_ERROR";

export const ACTION_DELETE_FAQ = "DELETE_FAQ";
export const ACTION_DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const ACTION_DELETE_FAQ_ERROR = "DELETE_FAQ_ERROR";
export const ACTION_UPDATE_AFTER_DELETE_FAQ_SUCCESS = "UPDATE_AFTER_DELETE_FAQ_SUCCESS"

export const ACTION_UPDATE_FAQ = "UPDATE_FAQ";
export const ACTION_UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const ACTION_UPDATE_FAQ_ERROR = "UPDATE_FAQ_ERROR";
export const ACTION_EDIT_FAQ_SUCCESS = "EDIT_FAQ_SUCCESS";

export const ACTION_GET_COMMENT = "GET_COMMENT";
export const ACTION_GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const ACTION_GET_COMMENT_ERROR = "GET_COMMENT_ERROR";
export const ACTION_UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";

export const ACTION_GET_CATEGORIES = "GET_CATEGORIES";
export const ACTION_GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const ACTION_GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const ACTION_GET_FAQ_CATEGORIES = "GET_FAQ_CATEGORIES";
export const ACTION_GET_FAQ_CATEGORIES_SUCCESS = "GET_FAQ_CATEGORIES_SUCCESS";
export const ACTION_GET_FAQ_CATEGORIES_ERROR = "GET_FAQ_CATEGORIES_ERROR";

export const ACTION_USER_CREATE_THREAD = "USER_CREATE_THREAD";
export const ACTION_USER_ADD_COMMENT = "USER_ADD_COMMENT";
export const ACTION_USER_CONFIRM_CLOSE = "USER_CONFIRM_CLOSE";
export const ACTION_USER_RATE = "USER_RATE";
export const ACTION_ADMIN_ADD_COMMENT = "ADMIN_ADD_COMMENT";
export const ACTION_ADMIN_REQUEST_CLOSE = "ADMIN_REQUEST_CLOSE";

export const ACTION_GET_DETAIL_COMMENT = "GET_DETAIL_COMMENT";
export const ACTION_GET_DETAIL_COMMENT_SUCCESS = "GET_DETAIL_COMMENT_SUCCESS";
export const ACTION_GET_DETAIL_COMMENT_ERROR = "GET_DETAIL_COMMENT_ERROR";

export const ACTION_ADD_THREAD_INTO_LIST_NOTI = "ADD_THREAD_INTO_LIST_NOTI";
export const ACTION_DELETE_THREAD_INTO_LIST_NOTI = "DELETE_THREAD_INTO_LIST_NOTI";

export const ACTION_GET_NOTIFY = "GET_NOTIFY";
export const ACTION_GET_NOTIFY_SUCCESS = "GET_NOTIFY_SUCCESS";
export const ACTION_GET_NOTIFY_ERROR = "GET_NOTIFY_ERROR";
export const ACTION_ADD_NOTIFY = "ADD_NOTIFY";
export const ACTION_DELETE_NOTIFY = "DELETE_NOTIFY";

export const ACTION_VERIFY_ADMIN_REQUEST = "VERIFY_ADMIN_REQUEST";
export const ACTION_VERIFY_ADMIN_SUCCESS = "VERIFY_ADMIN_SUCCESS";
export const ACTION_VERIFY_ADMIN_ERROR = "VERIFY_ADMIN_ERROR";

export const ACTION_INVITE_REQUEST = "INVITE_REQUEST";
export const ACTION_INVITE_SUCCESS = "INVITE_SUCCESS";
export const ACTION_INVITE_ERROR = "INVITE_ERROR";
export const ACTION_NEW_INVITE = "NEW_INVITE";

// export const ACTION_CREATE_NEW_THREAD = "CREATE_NEW_THREAD";
// export const ACTION_CREATE_NEW_THREAD_SUCCESS = "CREATE_NEW_THREAD_SUCCESS";
// export const ACTION_CREATE_NEW_THREAD_ERROR = "CREATE_NEW_THREAD_ERROR";


// export const ACTION_RATING_USER = "RATING_USER";
// export const ACTION_RATING_USER_SUCCESS = "RATING_USER_SUCCESS";
// export const ACTION_RATING_USER_ERROR = "RATING_USER_ERROR";

// export const ACTION_ADD_COMMENT = "ADD_COMMENT";
// export const ACTION_ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
// export const ACTION_ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";

// export const ACTION_CLOSE_THREAD = "CLOSE_THREAD";
// export const ACTION_CLOSE_THREAD_SUCCESS = "CLOSE_THREAD_SUCCESS";
// export const ACTION_CLOSE_THREAD_ERROR = "CLOSE_THREAD_ERROR";


