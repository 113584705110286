import { ACTION_INVITE_REQUEST, ACTION_INVITE_ERROR, ACTION_INVITE_SUCCESS, ACTION_NEW_INVITE} from '.'
import { logout } from './logout'
import * as api from '../../../api'

export const inviteRequest = () => ({
    type: ACTION_INVITE_REQUEST,
    payload: {}
})

export const inviteSuccess = data => ({
    type: ACTION_INVITE_SUCCESS,
    payload: { data }
})

export const inviteError = msg => ({
    type: ACTION_INVITE_ERROR,
    payload: { msg }
})

export const newInviteSuccess = data => ({
    type: ACTION_NEW_INVITE,
    payload: {}
})

export const inviteAdminJoin = (invite) => {
    return dispatch => {
        dispatch(inviteRequest(invite))

        api.invite(invite)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(inviteError("error"));
                    return
                }
                dispatch(inviteSuccess(invite.mid))
                // dispatch(newInviteSuccess(invite.tid))
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(inviteError("system error"))
            })
    }
}