import { ACTION_REPLY, ACTION_REPLY_SUCCESS, ACTION_REPLY_ERROR, ACTION_UPDATE_COMMENT_SUCCESS } from ".";
import { logout } from "./logout";
import * as api from "../../../api";

export const replyRequest = (newComment) => ({
    type: ACTION_REPLY,
    payload: {
        newComment: newComment
    }
})

export const replySuccess = data => ({
    type: ACTION_REPLY_SUCCESS,
    payload: {
        data
    }
})

export const updateCommentSuccess = data => ({
    type: ACTION_UPDATE_COMMENT_SUCCESS,
    payload: {
        data
    }
})

export const replyError = msg => ({
    type: ACTION_REPLY_ERROR,
    payload: {
        msg
    }
})

export const reply = (newComment) => {
    return dispatch => {
        dispatch(replyRequest(newComment))

        let imgs = []
        let oriImgs = newComment.images
        oriImgs.map(img => {
            imgs.push(img.substring(img.indexOf('base64,') + 7))
        })
        newComment.images = imgs

        api.reply(newComment)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(replyError("error"));
                    return
                }
                // const newState = {...newComment, time: Date().toLocaleString(), mid: 1, imgbase64: true, images: oriImgs}
                // console.log(newState)
                // dispatch(updateCommentSuccess(newState))
                dispatch(replySuccess(data))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(replyError("system error"))
            });
    }
}