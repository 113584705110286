import { ACTION_GET_CATEGORIES, ACTION_GET_CATEGORIES_ERROR, ACTION_GET_CATEGORIES_SUCCESS } from '.'
import { logout } from './logout'
import * as api from '../../../api'

export const getAllCategoriesRequest = () => ({
    type: ACTION_GET_CATEGORIES,
    payload: {}
})

export const getAllCategoriesSuccess = data => ({
    type: ACTION_GET_CATEGORIES_SUCCESS,
    payload: { data }
})

export const getAllCategoriesError = msg => ({
    type: ACTION_GET_CATEGORIES_ERROR,
    payload: { msg }
})

export const getAllCategories = () => {
    return dispatch => {
        dispatch(getAllCategoriesRequest())

        api.getCategories()
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getAllCategoriesError("error"));
                    return
                }
                dispatch(getAllCategoriesSuccess(data.categories))
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getAllCategoriesError("system error"))
            })
    }
}