import Container from 'react-bootstrap/Container';
import { Form, Modal, Button } from 'react-bootstrap';
import { RiAddBoxFill } from 'react-icons/ri';
import React, { useState, useEffect, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaClosedCaptioning, FaRegEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ".\./index.css";
import 'typeface-quicksand';
import styles from './faq.module.css';
import { RiLogoutBoxRLine } from "react-icons/ri";
import { useSelector, useDispatch } from 'react-redux';
import * as getFaqsAction from '../redux/store/actions';
import * as createFaqAction from '../redux/store/actions';
import * as getFaqCategoriesAction from '../redux/store/actions';
import * as updateFaqAction from '../redux/store/actions';
import * as deleteFaqAction from '../redux/store/actions';
import Loading from '../components/loading';
import Header from '../components/Header';
import { AiOutlineSortAscending } from 'react-icons/ai';
import { BiSortAlt2 } from 'react-icons/bi';

export default function FAQ() {
	const [isActive, setIsActive] = useState(false);
	const [showCreate, setShowCreate] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [indexDlt, setIndexDlt] = useState(0);
	const cateRef = useRef();
	const questionRef = useRef('');
	const answerRef = useRef('');
	const isLoading = useSelector(state => state.faqCategories.isLoading || 
		state.faqs.isLoading || state.detailfaq.isLoading)

	const [fid, setFid] = useState(0);
	const [category, setCategory] = useState('');
	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');
	const [isError, setIsError] = useState(false);
	const edit_err_msg = useSelector(state => state.faqUpdate.erro_msg)
	const create_err_msg = useSelector(state => state.createfaq.erro_msg)
	const isCreatedLoading = useSelector(state => state.createfaq.isLoading)
	const [listSort, setListSort] = useState([])
	const [isSort, setIsSort] = useState(false)
	const faqs = useSelector(state => state.faqs.data)
	const [listFaqs, setListFaqs] = useState([])

	// const fidNew = useSelector(state => state.createFaq.data.id)

	useEffect(() => {

		fetchFaqs()
		fetchFaqCategories()
	}, [])


	useEffect(() => {
		if (faqs != undefined) {
			console.log(faqs)
			setListFaqs(faqs)
		}
	}, [faqs])

	const fetchFaqCategories = async () => {
		dispatch(getFaqCategoriesAction.getFaqCategories())
	}
	const listFaqCategories = useSelector(state => state.faqCategories.data)
	useEffect(() => {
		if (create_err_msg === undefined) {
			console.log("check resey")
			setShowCreate(false)
			setIsActive(false)
		}
	}, [isCreatedLoading])

	const handleShowEdit = (id, cate, ques, ans, index) => {
		setIsActive(true)
		setShowEdit(true);
		setCategory(cate);
		setQuestion(ques);
		setAnswer(ans);
		setFid(id);
		setIndexDlt(index)
	}
	const handleShowDelete = (id, index) => {
		setIsActive(true)
		setShowDelete(true);
		setFid(id);
		setIndexDlt(index)
	}
	const handleShowCreate = () => {
		setShowCreate(true);
		setIsActive(true)
	}

	function handleSaveEdit(e) {
		e.preventDefault();
		const editFaq = {
			fid: fid,
			title: questionRef.current.value.trim(),
			text: answerRef.current.value.trim(),
			categories: [cateRef.current.value]
		}
		if (editFaq.title.trim() != '' && editFaq.text.trim() != '') {
			dispatch(updateFaqAction.updateFaq(editFaq))
			if (edit_err_msg !== undefined) {
				setIsError(true)
				alert("An error has occurred, please try again")
			}
			setIsActive(false)
			setShowEdit(false)


		}
	}
	const handleConfirmationYes = (e) => {
		e.preventDefault();


		setListFaqs(current => {
			let copy = [...current]
			delete copy[indexDlt]
			return copy
		})
		dispatch(deleteFaqAction.deleteFaq(fid))
		setShowDelete(false)
		setIsActive(false)


		// }

	}
	// useEffect(() => {
	// 	console.log("check 123")
	// 	if(delete_err_msg !== undefined){
	// 		alert("An error has occurred, please try again")
	// 	}

	// 	console.log(delete_err_msg)
	// }, [delete_err_msg])
	const handleHidden = (e) => {
		e.preventDefault();
		setShowCreate(false);
		setIsActive(false)
	}

	const handleCreate = (e) => {
		e.preventDefault();
		console.log("start create")
		console.log(answerRef)
		const newFaq = {
			title:  questionRef.current.value.trim(),
			text:  answerRef.current.value.trim(),
			categories: [cateRef.current.value]
		}
		console.log(newFaq.title)
		if (newFaq.title.trim() != '' && newFaq.text.trim() != '') {
			setListFaqs(current => {
				current = [...current, newFaq]
			})
			dispatch(createFaqAction.createFaq(newFaq))
			setShowCreate(false)
			setIsActive(false)
		}
	}


	const handleCancelDelete = () => {
		setShowDelete(false);
		setIsActive(false)
	}
	const handleCancelEdit = () => {
		setShowEdit(false);
		setIsActive(false)
	}

	const dispatch = useDispatch();
	const fetchFaqs = async () => {
		dispatch(getFaqsAction.getAllFaqs())
	}




	const handleSort = (e) => {
		console.log("check")
		console.log(listFaqs)
		e.preventDefault()
		if (isSort) {
			setListFaqs(listFaqs.sort((a, b) => a.categories[0].toLowerCase() > b.categories[0].toLowerCase() ? -1 : 1))
			setIsSort(!isSort)
		} else {
			setListFaqs(listFaqs.sort((a, b) => a.categories[0].toLowerCase() > b.categories[0].toLowerCase() ? 1 : -1))
			setIsSort(!isSort)
		}
	}

	if (listFaqs !== undefined) {
		return (
			<>
				<Header />
				<div style={{ backgroundColor: isActive ? '#E5E5E5' : '' }} className={styles.faqpage}>

					<div className={styles.faq} >FAQ <RiAddBoxFill onClick={(e) => handleShowCreate()} className={styles.addIcon} /></div>
					<table className={styles.tableBorder}>
						<thead>
							<tr>
								<th>Category<button style={{ border: "none", backgroundColor: "transparent" }}><BiSortAlt2 onClick={(e) => { handleSort(e) }} style={{ fontSize: "1.3em" }} /></button> </th>
								<th>Question</th>
								<th>Answer</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{listFaqs.map((faq, index) =>
								<tr key={faq + index}>
									<td >{faq.categories[0]}</td>
									<td >{faq.title}</td>
									<td ><div className={styles.answer}>{faq.text}</div></td>
									<td ><FaRegEdit onClick={() => handleShowEdit(faq.id, faq.categories[0], faq.title, faq.text, index)} className={styles.editFaq} /></td>
									<td ><AiOutlineDelete onClick={() => handleShowDelete(faq.id, index)} className={styles.deleteFaq} /></td>
								</tr>
							)}

						</tbody>
					</table>

					{showEdit && <div className={styles.addFaq}>
						<Form className={styles.formFieldsAddFaq}>
							<div className={styles.formField}>
								<label className={styles.formFieldLabel} htmlFor="name">Category&nbsp;&nbsp;&nbsp;&nbsp;</label>
								<select ref={cateRef} className={styles.formFieldInputCategory}>
									{listFaqCategories != undefined && listFaqCategories.map((cate) => (
										<option value={cate.id} name="cate" key={cate.id}>{cate.name}</option>
									))}
								</select>
							</div>
							<div className={styles.formField}>
								<label className={styles.formFieldLabel} htmlFor="name">Question</label>
								<input
									type="text"
									className={styles.formFieldInputQuestion}
									defaultValue={question}
									ref={questionRef}
								></input>
							</div>
							<div className={styles.formField}>
								<label className={styles.formFieldLabel} htmlFor="name">Answer</label>
								<textarea
									type="text"
									rows="5"
									className={styles.formFieldInputAns}
									ref={answerRef}
								>{answer}</textarea>
							</div>
							<div className={styles.btn}>
								<div className={styles.cancel}><button onClick={handleCancelEdit} className={styles.formFieldButtonCancel}>Cancel</button></div>
								<div className={styles.save}><button className={styles.formFieldButtonSave} onClick={(e) => {handleSaveEdit(e)}}>Save</button></div>
							</div>
						</Form>
					</div>}
					{showDelete && <div className={styles.confirm}>
						<div className={styles.text}>
							Bạn có chắc chắn muốn xóa tệp này?
						</div>
						<div>
							<button
								className={styles.btnNo}
								onClick={handleCancelDelete}>
								Cancel
							</button>
							<Link to={"/faq"}><button
								className={styles.btnYes}
								onClick={(e) => { handleConfirmationYes(e) }}>
								Yes
							</button></Link>
						</div>
					</div>}
					{showCreate && <div className={styles.addFaq}>
						<div className={styles.back}><button onClick={(e) => { handleHidden(e) }} className={styles.buttonBack}>x</button></div>
						<Form className={styles.formFieldsAddFaq}>
							<div className={styles.formField}>
								<label className={styles.formFieldLabel} htmlFor="name">Category&nbsp;&nbsp;&nbsp;&nbsp;</label>
								<select ref={cateRef} className={styles.formFieldInputCategory}>
									{listFaqCategories != undefined && listFaqCategories.map((cate) => (
										<option value={cate.id} name="cate" key={cate.id}>{cate.name}</option>
									))}
								</select>
							</div>
							<div className={styles.formField}>
								<label className={styles.formFieldLabel} htmlFor="name">Question</label>
								<input
									type="text"
									className={styles.formFieldInputQuestion}
									ref={questionRef}
								></input>
							</div>
							<div className={styles.formField}>
								<label className={styles.formFieldLabel} htmlFor="name">Answer</label>
								<textarea
									type="text"
									rows="5"
									className={styles.formFieldInputAns}
									ref={answerRef}
								></textarea>
							</div>
							<div className={styles.btn}>
								<button onClick={(e) => { handleCreate(e) }} className={styles.formFieldButton}>Create</button>{" "}
							</div>
						</Form>
					</div>}
					{isLoading && <Loading />}
				</div>
			</>
		);
	}



}