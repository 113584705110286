import { ACTION_GET_NOTIFY, ACTION_GET_NOTIFY_SUCCESS, ACTION_GET_NOTIFY_ERROR, ACTION_ADD_NOTIFY, ACTION_DELETE_NOTIFY } from "../actions";

const initState = {
    erro_msg: undefined,
    data: [],
    isLoading: false
}


export const getNotifiesReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case ACTION_GET_NOTIFY:
            return { ...state, isLoading: true, erro_msg: undefined }

        case ACTION_GET_NOTIFY_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data, erro_msg: undefined }

        case ACTION_GET_NOTIFY_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }

        case ACTION_ADD_NOTIFY:
            let nextState = {...state}
            nextState.data = [...state.data, {threadId: action.payload.data}]
            return nextState

        case ACTION_DELETE_NOTIFY:
            let newState = {...state}
            newState.data = newState.data.filter((item) => item.threadId !== action.payload.data)
            return newState
        default:
            return state;
    }
}