import { combineReducers } from 'redux';
  
import { verifyTokenReducer } from './verify-token';
import { getNewThreadsReducer, getOngoingThreadsReducer, getHistoryThreadsReducer } from './getThreads';
import { getAllCommentsReducer } from './getComment';
import {signinReducer} from './signin';
import { replyReducer } from './reply';
import { closeReducer } from './close';
import { getAllFaqsReducer} from './getfaqs';
import { createFaqReducer } from './createfaq';
import { detailFaqReducer } from './faqdetail';
import { deleteFaqReducer } from './deleteFaq';
import { updateFaqReducer } from './updateFaq';
import { getThreadDetailReducer } from './detailThread';
import { getAllCategoriesReducer } from './getCategories';
import { getFaqCategoriesReducer } from './getFaqCate';
import { detailCommentReducer } from './getDetailComment';
import { verifyAdminReducer } from './verify';
import { getNotifiesReducer } from './getnotify';
import { inviteReducer } from './invite';
import { websocketReducer } from './ws';

import {persistReducer} from "redux-persist"
import storage from "redux-persist/lib/storage"


const authPersistConfig = {
    key: "auth",
    storage: storage,
    whitelist: ["isLoggedIn", "adminName"]
}

// const listThreadsConfig = {
//     key: "list",
//     storage: storage,
//     whitelist: ["data"]
// }

export default combineReducers({
    signin: persistReducer(authPersistConfig, signinReducer),
    token: verifyTokenReducer,
    newthreads: getNewThreadsReducer,
    ongoingthreads: getOngoingThreadsReducer,
    historythreads: getHistoryThreadsReducer,
    comments: getAllCommentsReducer,
    reply: replyReducer,
    close: closeReducer,
    faqs: getAllFaqsReducer,
    createfaq: createFaqReducer,
    detailfaq: detailFaqReducer,
    faqDelete: deleteFaqReducer,
    faqUpdate: updateFaqReducer,
    allCategories: getAllCategoriesReducer,
    faqCategories: getFaqCategoriesReducer,
    threadDetail : getThreadDetailReducer,
    detailComment: detailCommentReducer,
    // listThreadsNoti: persistReducer(listThreadsConfig, listThreadsReducer),
    notifies: getNotifiesReducer,
    verifyAdmin: verifyAdminReducer,
    invite: inviteReducer,
    ws: websocketReducer
});