import { ACTION_USER_ADD_COMMENT, ACTION_USER_CONFIRM_CLOSE, ACTION_USER_CREATE_THREAD, ACTION_USER_RATE, ACTION_ADMIN_REQUEST_CLOSE, ACTION_ADMIN_ADD_COMMENT } from ".";

export const userCreateNewThread = data => ({
    type: ACTION_USER_CREATE_THREAD, 
    payload: {data}
})

export const userAddComment = data => ({
    type: ACTION_USER_ADD_COMMENT,
    payload: {data}
})

export const userConfirm = data => ({
    type: ACTION_USER_CONFIRM_CLOSE,
    payload: {data}
})

export const userRate = data => ({
    type: ACTION_USER_RATE, 
    payload: {data}
})

export const adminAddComment = data => ({
    type: ACTION_ADMIN_ADD_COMMENT,
    payload: {data}
})

export const adminRequestClose = data => ({
    type: ACTION_ADMIN_REQUEST_CLOSE, 
    payload: {data}
})

export const getDataUserCreateNewThread = (packet) => {
    return dispatch => {
        dispatch(userCreateNewThread(packet))
    }
}

export const getDataUserAddComment = (packet) => {
    return dispatch => {
        dispatch(userAddComment(packet))
    }
}

export const getDataUserConfirm = (packet) => {
    return dispatch => {
        dispatch(userConfirm(packet))
    }
}

export const getDataUserRate = (packet) => {
    return dispatch => {
        dispatch(userRate(packet))
    }
}

export const getDataAdminAddComment = (packet) => {
    return dispatch => {
        dispatch(adminAddComment(packet))
    }
}

export const getDataAdminClose = (packet) => {
    return dispatch => {
        dispatch(adminRequestClose(packet))
    }
}