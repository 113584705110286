import {ACTION_REPLY, ACTION_REPLY_SUCCESS, ACTION_REPLY_ERROR} from '../actions';

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const replyReducer = (state = initState, action = {}) => {
    switch(action.type){
        case ACTION_REPLY:
            return {...state, isLoading: true, erro_msg: undefined}

        case ACTION_REPLY_SUCCESS:
            return { ...state, isLoading: false, data:action.payload.data, erro_msg: undefined}

        case ACTION_REPLY_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }

        default:
            return state;
    }
}