import { logout } from './logout';
import * as api from '../../../api';
import { ACTION_DELETE_FAQ, ACTION_DELETE_FAQ_SUCCESS, ACTION_DELETE_FAQ_ERROR, ACTION_UPDATE_AFTER_DELETE_FAQ_SUCCESS } from '.';


export const deleteFaqRequest = () => ({
    type: ACTION_DELETE_FAQ,
    payload: {}
})

export const deleteFaqSuccess = data => ({
    type: ACTION_DELETE_FAQ_SUCCESS,
    payload: { data }
})

export const deleteSuccess = data => ({
    type: ACTION_UPDATE_AFTER_DELETE_FAQ_SUCCESS,
    payload: { data }
})


export const deleteFaqError = msg => ({
    type: ACTION_DELETE_FAQ_ERROR,
    payload: { msg }
})

export const deleteFaq = (fid) => {
    return dispatch => {
        dispatch(deleteFaqRequest())

        api.deleteFaq(fid)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    console.log("check error")
                    dispatch(deleteFaqError("error"));
                    return
                }
                dispatch(deleteSuccess(fid))
                console.log("check success")
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(deleteFaqError("system error"))
            })
    }
}

