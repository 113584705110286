import React, { useState, useEffect } from "react";
import {
  Link,
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Header from './components/Header';
import Filter from './components/Filter';
import Login from "./routes/login";
import axios from "axios";
import History from "./routes/history";
import Home from "./routes/home";
import FAQ from "./routes/faq";
import { Switch } from "react-router-dom";
import { SecureScreen } from "./routes/secure";
import NotFound from "./routes/notfound";

import { useSelector } from "react-redux";
import { ThreadTab } from "./routes/home";
import { ThreadDetails } from "./routes/threadDetails";
import NoData from "./routes/nodata";

// const apiURL = 'http://localhost:5000/login/admin';

// const getJwt = async () => {
//   const { data } = await axios.get(`/login`);
//   setJwt(data.token);
// }
// const getFoods = async () => {
//   try {
//     const { data } = await axios.get(`/foods`);
//     setFoods(data);
//     setFetchError(null);
//   } catch (err) {
//     setFetchError(err.message);
//   }
// };

export default function App() {
  const [auth, setAuth] = useState('');

  const fetchCsrf = () => {
    // const instance = axios.create({
    //   timeout: 1000
    // })
    // instance.get(`/api/csrf-token`).then(response => {
    //   axios.defaults.headers.post['X-CSRF-Token'] = response.data.CsrfToken;
    // })
  }

  const isLoggedIn = useSelector(state => state.signin.isLoggedIn)

  useEffect(() => {
    // fetchCsrf()
  }, [])

  return (
	  
    // <CookieProvider>
     <>
      <Switch>
        <Route exact path={"/history"} render={(props) => (<SecureScreen component={<History {...props}  />} />)} />
        <Route exact path={"/"} render={(props) => (<SecureScreen component={<ThreadTab {...props}  />} />)} />
        <Route exact path={"/faq"} render={(props) => (<SecureScreen component={<FAQ {...props}  />} />)} />
        {/* <Route exact path={"/thread_detail"} render={(props) => (<SecureScreen component={<Thread_Detail {...props} />} />)} /> */}
        <Route exact path={"/thread_detail/:tid"} render={(props) => (<SecureScreen component={<ThreadDetails {...props}  />} />)}/>
        <Route exact path={"/no_data"} component={NoData}/>
        <Route path="*" component={NotFound} />
      </Switch>
      </>
    // </CookieProvider>

  );
}