import { ACTION_DELETE_FAQ, ACTION_DELETE_FAQ_SUCCESS, ACTION_DELETE_FAQ_ERROR, ACTION_UPDATE_AFTER_DELETE_FAQ_SUCCESS } from '../actions';


const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const deleteFaqReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case ACTION_DELETE_FAQ:
            return { ...state, isLoading: true, erro_msg: undefined }

        case ACTION_DELETE_FAQ_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data, erro_msg: undefined }

        // case ACTION_UPDATE_AFTER_DELETE_FAQ_SUCCESS:

        //     let nextState = { ...state }
        //     nextState.data = nextState.data.filter((faqList) => faqList.id !== action.payload.data)
        //     return nextState

        case ACTION_DELETE_FAQ_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }

        default:
            return state;
    }
}