import {ACTION_DETAIL_FAQ, ACTION_DETAIL_FAQ_SUCCESS, ACTION_DETAIL_FAQ_ERROR} from '../actions';

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const detailFaqReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case ACTION_DETAIL_FAQ:
            return { ...state, isLoading: true, erro_msg: undefined }

        case ACTION_DETAIL_FAQ_SUCCESS:
            return { ...state, isLoading: false, data:  action.payload.data, erro_msg: undefined }

        case ACTION_DETAIL_FAQ_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }
            
        default:
            return state;
    }
}