import { ACTION_SIGININ, ACTION_SIGNIN_SUCCESS, ACTION_SIGNIN_ERROR } from ".";
import * as api from "../../../api";

export const signinRequest = (account) => ({
    type: ACTION_SIGININ,
    payload: {
        account: account
    }
})

export const signinSuccess = data => ({
    type: ACTION_SIGNIN_SUCCESS,
    payload: {
        data
    }
})


export const signinError = msg => ({
    type: ACTION_SIGNIN_ERROR,
    payload: {
        msg
    }
})


export const signin = (account) => {
    return dispatch => {
        dispatch(signinRequest(account))

        api.signin(account)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(signinError("error"));
                    return
                }
                let newData = {
                    token: data.token,
                    name: account.username
                }
                dispatch(signinSuccess(newData))
            }).catch((err) => {
                console.log(err)
                dispatch(signinError("system error"))
            });
    }
}