import { useEffect } from "react"
import { useSelector } from "react-redux"
import Login from "./login"

export const SecureScreen = (props) => {
    const { component } = props
    const isLoggin = useSelector(state => state.signin.isLoggedIn)

    return (
        <>
            {isLoggin ? <>{component}</> : <Login />}
        </>
    )
}

