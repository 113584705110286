import {ACTION_GET_THREAD_DETAIL, ACTION_GET_THREAD_DETAIL_SUCCESS, ACTION_GET_THREAD_DETAIL_ERROR} from '../actions'
const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false,
    count : 0
}

export const getThreadDetailReducer = (state = initState, action = {}) => {
    switch(action.type){
        case ACTION_GET_THREAD_DETAIL:
            return {...state, isLoading: true,   erro_msg: undefined}

        case ACTION_GET_THREAD_DETAIL_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data, erro_msg: undefined , count: state.count + 1}

        case ACTION_GET_THREAD_DETAIL_ERROR:
            return { ...state, isLoading: false,data: undefined, erro_msg: action.payload.msg , count: state.count + 1 }

        default:
            return state;
    }
}