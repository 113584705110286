import React, { Component, useState, useEffect, useRef } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import styles from './login.module.css';
import * as loginAction from '../redux/store/actions';
import { useSelector, useDispatch } from 'react-redux';
import Loading from "../components/loading";

// const apiURL = 'http://localhost:5000/login/admin';



const Login = () => {

	const dispatch = useDispatch();
	const usernameRef = useRef('');
	const passRef = useRef();
	const [adminName, setAdminName] = useState('');
	const [adminPass, setAdminPass] = useState('');
	let access_token;
	let path;

	const [csrf, setCSRF] = useState("")
	const isLoading = useSelector(state => state.signin.isLoading)


	useEffect(() => {
		// fetchToken()
		// setCSRF(axios.defaults.headers.post['X-CSRF-Token'])
	})

	function HandleLogin(e) {
		e.preventDefault();
		// setAdminName(usernameRef.current.value);
		// setAdminPass(passRef.current.value);
		const account = {
			username: adminName,
			password: adminPass
		}
		dispatch(loginAction.signin(account))
		console.log(account)
		
	}


	return (
		<div className={styles.formCenter}>
			<div className={styles.title}>LOGIN</div>
			<form className="formFields">
				<div className={styles.formField} >
					<label className={styles.formFieldLabel} htmlFor="name"></label>
					<input
						type="text"
						id="name"
						ref={usernameRef}
						className={styles.formFieldInput}
						placeholder="User Name"
						name="name"
						value={adminName}
						onChange={(e) => setAdminName(e.target.value)}
					/>
				</div>
				<div className={styles.formField}>
					<label className={styles.formFieldLabel} htmlFor="password"></label>
					<input
						type="password"
						id="password"
						ref={passRef}
						className={styles.formFieldInput}
						placeholder="Password"
						name="password"
						value={adminPass}
						onChange={(e) => setAdminPass(e.target.value)}
					/>
				</div>
				<div className={styles.formField}>
					<label className={styles.formFieldCheckboxLabel}>
						<input
							className={styles.formFieldCheckbox}
							type="checkbox"
							name="hasAgreed"
						/>{" "}
						Remember{" "}
					</label>
				</div>

				<div className={styles.btn}>
					<button onClick={(e) => { HandleLogin(e) }} className={styles.formFieldButton}>Login</button>
				</div>
			</form>
			{isLoading && <Loading />}
		</div>
	);

}

export default Login;