import { logout } from './logout';
import * as api from '../../../api';
import { ACTION_GET_FAQS, ACTION_GET_FAQS_SUCCESS, ACTION_GET_FAQS_ERROR } from '.';

export const getFaqsRequest = () => ({
    type: ACTION_GET_FAQS,
    payload: {}
})

export const getFaqsSuccess = data => ({
    type: ACTION_GET_FAQS_SUCCESS,
    payload: { data }
})

export const getFaqsError = msg => ({
    type: ACTION_GET_FAQS_ERROR,
    payload: { msg }
})

export const getAllFaqs = () => {
    return dispatch => {
        dispatch(getFaqsRequest())

        api.getFaqs()
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getFaqsError("error"));
                    return
                }
                dispatch(getFaqsSuccess(data.lists))
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getFaqsError("system error"))
            })
    }
}

