import React, { useEffect, useState, useRef } from 'react';
import {
	AiOutlineHome,
	AiOutlineHistory,
	AiOutlineStar,
} from "react-icons/ai";
import { RiLogoutBoxRLine } from "react-icons/ri";

import styles from './header.module.scss';
import { NavLink, Redirect, useHistory, useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Form, FormControl, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector, useNa } from 'react-redux';
import * as logoutAction from "../redux/store/actions";
import * as actions from '../redux/store/actions';
import { AiOutlineSearch } from 'react-icons/ai';
import * as threadDetailAction from '../redux/store/actions'

import { generatePath } from 'react-router';



const Header = (props) => {

	const dispatch = useDispatch()
	const adminName = useSelector(state => state.signin.adminName)
	const [threadId, setThreadId] = useState(0)
	const [isSearch, setIsSearch] = useState(false)
	const idRef = useRef()
	const adminId = useSelector(state=> state.verifyAdmin.data)
	// const navigate = useNavigate();

	useEffect(() =>{
		fetchId()
	}, [])

	const fetchId = async () => {
		dispatch(actions.verifyAdmin(true))
	}

	const history = useHistory()
	const location = useLocation()

	const logout = async () => {
		dispatch(logoutAction.logout())
	}

	const handleSearch = (e) => {
		e.preventDefault();

		if (threadId !== 0) {
			idRef.current.value = ('')
			
			setIsSearch(true)
			// const path  = generatePath("/thread_detail/:tid", {tid: threadId})
			// history.replace(path)
			// history.push(path);
		}

	}

	useEffect(() => {
		if(isSearch){
			setIsSearch(false)
		}
	}, [isSearch])
	return (
		<div className={styles.header}>
			{isSearch && <Redirect to={"/thread_detail/" + threadId} exact />}
			<Navbar bg="light" expand="lg">
				<Container fluid>
					{/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
					<Navbar.Toggle aria-controls="navbarScroll" />
					<Navbar.Collapse id="navbarScroll">
						<Nav
							className="me-auto my-2 my-lg-0"
							style={{ maxHeight: '100px' }}
							navbarScroll
						>
							<NavLink to={"/"} exact={true} className='nav-link' ><AiOutlineHome /><span>Home</span></NavLink>
							<NavLink to={"/history"} exact={true} className='nav-link'><AiOutlineHistory /><span>History</span></NavLink>
							<NavLink to={"/faq"} exact={true} className='nav-link'><AiOutlineStar /><span>FAQ</span></NavLink>
						</Nav>
						<div className="me-auto name ">
							ADMIN {adminName}_{adminId}
						</div>
						<Form className='searchFrom'>
							{/* <FormControl
								type="search"
								placeholder="Search thread ID"
								className="text-white bg-secondary"
								aria-label="Search"
							/> */}
							<button className='btn' onClick={(e) => { handleSearch(e) }}><AiOutlineSearch style={{color: "white", fontSize: "1.5em"}} /></button>
							<input className='inputText' ref={idRef} placeholder='Search thread ID' onChange={(e) => setThreadId(e.target.value)} ></input>
						</Form>
						<span className="me-right">
							<a className="nav-link" onClick={logout} href="#"><RiLogoutBoxRLine />  Logout</a>
						</span>
					</Navbar.Collapse>
				</Container>
			</Navbar>

		</div>

	);

}
export default Header;
