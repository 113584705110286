import { ACTION_INVITE_REQUEST, ACTION_INVITE_SUCCESS, ACTION_INVITE_ERROR} from '../actions';

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false, 
    count: 0
}

export const inviteReducer = (state = initState, action = {}) => {
    switch(action.type){
        case ACTION_INVITE_REQUEST:
            return {...state, isLoading: true, erro_msg: undefined, count: state.count}

        case ACTION_INVITE_SUCCESS:
            return { ...state, isLoading: false, data:action.payload.data, erro_msg: undefined, count: state.count + 1}

        case ACTION_INVITE_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg, count: state.count + 1 }

        default:
            return state;
    }
}