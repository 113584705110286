import { logout } from './logout';
import * as api from '../../../api';
import { ACTION_UPDATE_FAQ, ACTION_UPDATE_FAQ_ERROR, ACTION_UPDATE_FAQ_SUCCESS, ACTION_EDIT_FAQ_SUCCESS } from '.';

export const updateFaqRequest = () => ({
    type: ACTION_UPDATE_FAQ,
    payload: {}
})

export const updateFaqSuccess = data => ({
    type: ACTION_UPDATE_FAQ_SUCCESS,
    payload: { data }
})

export const editFaqSuccess = data => ({
    type: ACTION_EDIT_FAQ_SUCCESS,
    payload: {data}
})

export const updateFaqError = msg => ({
    type: ACTION_UPDATE_FAQ_ERROR,
    payload: { msg }
})

export const updateFaq = (editFaq) => {
    return dispatch => {
        dispatch(updateFaqRequest())

        api.updateFaq(editFaq)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(updateFaqError("error"));
                    return
                }
                // dispatch(updateFaqSuccess(data))
                dispatch(editFaqSuccess(editFaq))
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(updateFaqError("system error"))
            })
    }
}
