import { logout } from "./logout";
import { ACTION_GET_NEW_THREADS, ACTION_GET_NEW_THREADS_SUCCESS, ACTION_GET_NEW_THREADS_ERROR } from ".";
import { ACTION_GET_ONGOING_THREADS, ACTION_GET_ONGOING_THREADS_SUCCESS, ACTION_GET_ONGOING_THREADS_ERROR } from '.';
import { ACTION_GET_HISTORY_THREADS, ACTION_GET_HISTORY_THREADS_SUCCESS, ACTION_GET_HISTORY_THREADS_ERROR } from ".";
import * as api from "../../../api";

export const getNewThreadsRequest = () => ({
    type: ACTION_GET_NEW_THREADS,
    payload: {
    }
})

export const getNewThreadsSuccess = data => ({
    type: ACTION_GET_NEW_THREADS_SUCCESS,
    payload: {
        data
    }
})


export const getNewThreadsError = msg => ({
    type: ACTION_GET_NEW_THREADS_ERROR,
    payload: {
        msg
    }
})

export const getOngoingThreadsRequest = () => ({
    type: ACTION_GET_ONGOING_THREADS,
    payload: {
    }
})

export const getOngoingThreadsSuccess = data => ({
    type: ACTION_GET_ONGOING_THREADS_SUCCESS,
    payload: {
        data
    }
})


export const getOngoingThreadsError = msg => ({
    type: ACTION_GET_ONGOING_THREADS_ERROR,
    payload: {
        msg
    }
})

export const getHistoryThreadsRequest = () => ({
    type: ACTION_GET_HISTORY_THREADS,
    payload: {
    }
})

export const getHistoryThreadsSuccess = data => ({
    type: ACTION_GET_HISTORY_THREADS_SUCCESS,
    payload: {
        data
    }
})


export const getHistoryThreadsError = msg => ({
    type: ACTION_GET_HISTORY_THREADS_ERROR,
    payload: {
        msg
    }
})


export const getNewThreads = (type) => {
    return dispatch => {
        dispatch(getNewThreadsRequest())

        api.getThreads(type)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getNewThreadsError("error"));
                    return
                }
                dispatch(getNewThreadsSuccess(data.list))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getNewThreadsError("system error"))
            });
    }
}

export const getOngoingThreads = (type) => {
    return dispatch => {
        dispatch(getOngoingThreadsRequest())

        api.getThreads(type)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getOngoingThreadsError("error"));
                    return
                }
                dispatch(getOngoingThreadsSuccess(data.list))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getOngoingThreadsError("system error"))
            });
    }
}

export const getHistoryThreads = (type) => {
    return dispatch => {
        dispatch(getHistoryThreadsRequest())

        api.getThreads(type)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getHistoryThreadsError("error"));
                    return
                }
                dispatch(getHistoryThreadsSuccess(data.list))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getHistoryThreadsError("system error"))
            });
    }
}

