import * as api from '../../../api';
import { ACTION_GET_NOTIFY, ACTION_GET_NOTIFY_SUCCESS, ACTION_GET_NOTIFY_ERROR, ACTION_ADD_NOTIFY, ACTION_DELETE_NOTIFY } from '.';
import { logout } from './logout';

export const getNotifyRequest = () => ({
    type: ACTION_GET_NOTIFY,
    payload: {}   
})

export const getNotifySuccess = data => ({
    type: ACTION_GET_NOTIFY_SUCCESS,
    payload: {data}
})

export const getNotifyError = msg => ({
    type: ACTION_GET_NOTIFY_ERROR,
    payload: {msg}
})

export const addNotifyRequest = data => ({
    type: ACTION_ADD_NOTIFY, 
    payload: {data}
})

export const deleteNotifyRequest = data => ({
    type: ACTION_DELETE_NOTIFY,
    payload: {data}
})

export const getNotifies = () => {
    return dispatch => {
        dispatch(getNotifyRequest())

        api.getNotify()
        .then(({ data }) => {
            if (data.result.code !== 0) {
                dispatch(getNotifyError("error"));
                return
            }
            dispatch(getNotifySuccess(data.list))
        })
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 401) {
                    dispatch(logout())
                    return
                }
            }
            dispatch(getNotifyError("system error"))
        })
    }
}

export const addThreadIntoNotifyList = (tid) => {
    return dispatch => {
        dispatch(addNotifyRequest(tid))
    }
}


export const deleteThreadIntoNotifyList = (tid) => {
    return dispatch => {
        dispatch(deleteNotifyRequest(tid))
    }
}