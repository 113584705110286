import axios from "axios";

export const anonRequest = (_data, url) => {
    let data = JSON.stringify(_data);
    let config = {
        method: 'post',
        url: url,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };
    return axios(config);
}